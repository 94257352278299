<template>
  <DrawerView :visible="visible" :width="720" :title="'查看阶梯报价'" show-slot-title @cancel="handleCancel">
    <div class="priceDetails">
      <a-form-model
        :class="'form-cover1 form-cover'"
        ref="basicForm"
        :model="form"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        :colon="false"
      >
        <div class="card-info-box" >
          <div class="see-tag">
            <div class="see-tip">
              <a-icon type="exclamation-circle" style="color: #FF6026" theme="filled" />
              温馨提示:不同数量价格有差别，若有拆单注意数量区间价格~
            </div>
          </div>
          <div v-if=" quoteItem.length>0  && quoteItem[0].purchaseEnquiryLadder!=null" style="border: 1px solid#E8E9EB;margin-bottom: 20px" v-for="item in quoteItem">
            <div class="table-hd">
              {{item.skuName}}/报价信息
            </div>
            <a-row style="display: flex;width: 100%;" v-for=" (i,index) in item.purchaseEnquiryLadder">
              <a-col class="table-col table-borde r-bottom table-border-L">阶梯{{index+1}}</a-col>
              <a-col class="table-col-max table-border-bottom table-border-LR">
                 <span
                   v-if="index==0 && item.purchaseEnquiryLadder[index].intervalNumber&& item.purchaseEnquiryLadder.length>1">
                              {{ item.purchaseEnquiryLadder[index].intervalNumber
                   }}&nbsp;&nbsp;~&nbsp;&nbsp;{{ item.purchaseEnquiryLadder[index + 1].intervalNumber - 1 }}
                            </span>
                <span
                  v-if="index==1 && item.purchaseEnquiryLadder[index].intervalNumber && item.purchaseEnquiryLadder.length==2">
                              ≥&nbsp;&nbsp;{{ item.purchaseEnquiryLadder[index].intervalNumber }}
                            </span>
                <span
                  v-else-if="index>=1 && index!=item.purchaseEnquiryLadder.length-1 && item.purchaseEnquiryLadder[index].intervalNumber ">
                              {{ item.purchaseEnquiryLadder[index].intervalNumber
                  }}&nbsp;&nbsp;~&nbsp;&nbsp;{{ item.purchaseEnquiryLadder[index + 1].intervalNumber - 1
                  }}
                            </span>
                <span
                  v-else-if="index==item.purchaseEnquiryLadder.length-1 && item.purchaseEnquiryLadder[index].intervalNumber">
                              ≥&nbsp;&nbsp;{{ item.purchaseEnquiryLadder[index].intervalNumber }}
                            </span>
                <span v-else></span>

<!--                数量  ≥ {{i.intervalNumber}}-->

              </a-col>
              <a-col class="table-col-max table-border-bottom table-border-R">报价单价/元：<span>{{i.unitPrice}}</span></a-col>
            </a-row>
          </div>
          <div v-if="quoteItem.length==0 || quoteItem[0].purchaseEnquiryLadder==null" style="border: 1px solid#E8E9EB;margin-bottom: 20px" v-for="item in dataSource">
            <div class="table-hd">
              {{item.skuName}}/报价信息
            </div>
            <a-row style="display: flex;width: 100%;" v-for=" (i,index) in item.purchaseEnquiryLadder">
              <a-col class="table-col table-border-bottom table-border-L">阶梯{{index+1}}</a-col>
              <a-col class="table-col-max table-border-bottom table-border-LR">
<!--                数量  ≥ {{i.intervalNumber}}-->
                <span
                  v-if="index==0 && item.purchaseEnquiryLadder[index].intervalNumber&& item.purchaseEnquiryLadder.length>1">
                              {{ item.purchaseEnquiryLadder[index].intervalNumber
                  }}&nbsp;&nbsp;~&nbsp;&nbsp;{{ item.purchaseEnquiryLadder[index + 1].intervalNumber - 1 }}
                            </span>
                <span
                  v-if="index==1 && item.purchaseEnquiryLadder[index].intervalNumber && item.purchaseEnquiryLadder.length==2">
                              ≥&nbsp;&nbsp;{{ item.purchaseEnquiryLadder[index].intervalNumber }}
                            </span>
                <span
                  v-else-if="index>=1 && index!=item.purchaseEnquiryLadder.length-1 && item.purchaseEnquiryLadder[index].intervalNumber ">
                              {{ item.purchaseEnquiryLadder[index].intervalNumber
                  }}&nbsp;&nbsp;~&nbsp;&nbsp;{{ item.purchaseEnquiryLadder[index + 1].intervalNumber - 1
                  }}
                            </span>
                <span v-else-if="index==item.purchaseEnquiryLadder.length-1 && item.purchaseEnquiryLadder[index].intervalNumber">
                              ≥&nbsp;&nbsp;{{ item.purchaseEnquiryLadder[index].intervalNumber }}
                            </span>
                <span v-else></span>
              </a-col>
              <a-col class="table-col-max table-border-bottom table-border-R">报价单价/元：<span>{{i.unitPrice}}</span></a-col>
            </a-row>
          </div>
        </div>
      </a-form-model>
    </div>
    <template v-slot:btnArea>
      <a-button @click="handleCancel">取消</a-button>
    </template>
  </DrawerView>
</template>
<script>
import { defineComponent } from 'vue'
import DrawerView from '@/components/plugins/drawerView.vue'
import JDictSelect from '@/components/plugins/JDictSelect.vue'
import { getAction, postAction } from '@/api/manage'
import uploadFile from '@/components/plugins/uploadFile.vue'
import UploadDrag from '@/components/plugins/uploadDrag.vue'
import { mapGetters } from 'vuex'
import ZPagination from '@/components/plugins/ZPagination.vue'
import ViewContract from '@/views/ContractManagement/modules/viewContract.vue'
import checkboxSearch from '@/components/plugins/checkboxSearch.vue'

export default defineComponent({
  components: { checkboxSearch, ViewContract, UploadDrag, uploadFile, JDictSelect, DrawerView, ZPagination }
  ,
  props: {
    dataSource: {
      type: Array,
      default: () => ([])
    },
  },
  data() {
    return {
      enquiryLadders: [],
      form: { modeType: 1 },
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
      formBaseInfo: {},
      formFile: {},
      pageNation: {
        pageNum: 1,
        pageSize: 20,
        total: 0
      },
      columns: [
        {
          title: '订单编号 ',
          dataIndex: 'orderNo',
          key: 'orderNo',
          width: 218
        },
        {
          title: '订单名称 ',
          dataIndex: 'orderTitle',
          key: 'orderTitle',
          width: 180,
          scopedSlots: { customRender: 'orderTitle' }
        },

        {
          title: '开票款项',
          dataIndex: 'paymentMethodDictName',
          key: 'paymentMethodDictName',
          width: 180,
          scopedSlots: { customRender: 'paymentMethodDictName' }
        },
        {
          title: '开票企业',
          dataIndex: 'supplierBusinessName',
          // align: 'center',
          key: 'supplierBusinessName',
          width: 240,
          scopedSlots: { customRender: 'supplierBusinessName' }
        },
        {
          title: '开票金额 ',
          dataIndex: 'invoiceAmount',
          key: 'invoiceAmount',
          width: 140,
          scopedSlots: { customRender: 'invoiceAmount' }
        }],
      visibleDel: false,
      visible: false, saleColumns: [
        {
          title: '开票内容',
          dataIndex: 'num',
          width: 176
        },
        {
          title: '规格型号',
          dataIndex: 'prePrice',
          scopedSlots: { customRender: 'prePrice' },
          width: 176
        },
        {
          title: '单位',
          dataIndex: 'unit',
          width: 176,
          scopedSlots: { customRender: 'unit' }
        },
        {
          title: '数量',
          dataIndex: 'total',
          width: 176,
          scopedSlots: { customRender: 'total' }
        }, {
          title: '税率',
          dataIndex: 'taxRate',
          width: 176,
          scopedSlots: { customRender: 'taxRate' }
        }, {
          title: '金额(元)',
          dataIndex: 'price',
          width: 176,
          scopedSlots: { customRender: 'price' }
        }
      ], dataSourceModel: [{
        num: '包装采购费',
        prePrice: '-',
        unit: '次',
        total: '1',
        taxRate: '17%',
        price: '200.00'
      }],
      uploadVnioicevisible: false,
      invoiceData: {},
      targetVoidId: null,
      loading: false,
      invoiceFileList: [],
      quoteItem:null

    }
  },
  created() {
  },
  methods: {
    ...mapGetters(['dataSource']),
    handleOpen(item) {
      console.log(item)
      this.quoteItem=item
      this.visible = true

    },
    handleCancel() {
      this.visible = false
    }, saleInfoDelete() {
      this.visibleDel = true
    },
    handleChangePage({ currentPage, pageSize }) {
      this.pageNation.pageNum = currentPage
      this.pageNation.pageSize = pageSize
      this.handleLoadData()
    },
    handleLoadData() {
      getAction('/v1/invoice/invoiceDetail', {
        id: this.targetVoidId
      }).then((res) => {
        const { success, data, message } = res
        if (success) {
          this.invoiceData = data
        } else {
          this.$message.warning(message)
        }
      })
    },
    submit() {
      this.$emit('ok')
      this.visible = false
    }, deleteLadder(index) {
      this.enquiryLadders.splice(index, 1)
    },
    toAddLadder() {
      this.enquiryLadders.push({})
    }
  }
})

</script>
<style lang="less" scoped>
.invoiceDetails {
  box-sizing: border-box;
  background-color: #ffffff;
}

.RequirementConfirmation {
  .header {
    &-title {
      color: #090b12;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    &-subtitle {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      margin-bottom: 15px;

      .copy {
        font-size: 13px;
        margin-left: 8px;
        font-weight: 500;
      }
    }
  }

  &-title {
    font-size: 15px;
    color: #605f5f;
    margin-bottom: 24px;
    height: 38px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #efefef;
    position: relative;

    &:before {
      background: #FF6026;
      display: inline-block;
      content: '';
      width: 5px;
      height: 16px;
      margin-right: 8px;
    }

    .title {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #000;
      margin-right: 10px;
    }

    .subTitle {
      font-weight: 400;
      font-size: 14px;
      color: #8d8e99;
    }
  }

  &-content {
    &-item {
      width: 100%;
      background: #fff;
      position: relative;

      .edit-btn {
        position: absolute;
        top: 4px;
        right: -15px;

        img {
          width: 15px;
          margin-right: 5px;
        }
      }
    }
  }

  ::v-deep .ant-form-item-label {
    line-height: 22px;

    label {
      color: #626161 !important;
    }
  }

  ::v-deep .ant-form-item-control {
    line-height: 22px;
    color: #131212;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }


  .last-label {
    width: 100%;
    height: 102px;
    background-image: url('~@/assets/longerBG.png');
    border-radius: 8px;
    display: flex;
    align-items: center;
    position: relative;

    .ant-form-item {
      margin-bottom: 0;
    }

    ::v-deep .ant-input {
      border-color: #ff9a57;
    }

    ::v-deep .ant-input-suffix {
      color: #131212;
    }

    .moneyIcon {
      position: absolute;
      bottom: 0;
      right: 19px;
    }
  }

  .numberInput {
    width: 320px;
    border-color: #ff7d2f;
    height: 38px;
    line-height: 38px;

    ::v-deep .ant-input-number-input {
      font-size: 18px;
      color: #131212;
      font-weight: 500;
    }
  }

}</style>
<style>
.see-tip {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  /* 文字色/标题+主要 */
  color: #262626;
  display: flex;
  align-items: center;
  gap: 8px;
}

.see-tag {
  display: flex;
  justify-content: space-between;
  padding: 10px 24px;
  background-color: #FF7D2F1A;
  margin-bottom: 16px;
  border-radius: 4px;


}
.table-hd{
  //width: 684px;
  padding: 10px 0px;
  justify-content: center;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  /* 中性色/标题 color_text_title */
  color: #131212;
  background-color: #F4F5F7;
  display: flex;

}
.table-col{
  width: 36%;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #131212;
  padding: 10px 16px;
  border: 1px solid #E8E9EB;
}
.table-col-max{
  width: 212px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #131212;
  padding: 10px 16px;
  border: 1px solid #E8E9EB;
}
.table-border-bottom{
  border-bottom: 0px !important;
}
.table-border-LR{
  border-left: 0px;
  border-right: 0px;
}

.table-border-L{
  border-left: 0px;
  border-bottom: 0px
}
.table-border-R{
  border-right: 0px;
}
</style>


